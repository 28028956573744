import React, { useState, useEffect } from 'react';
import auth from './../../../firebase.js';
import './ForgotPasswordDialog.css';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';

const ForgotPasswordDialog = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [sendPasswordResetEmail, _ , error] = useSendPasswordResetEmail(auth);

    useEffect(() => {
        if (error === undefined) {
            return;
        }
        if (error.message.includes("auth/invalid-email")) {
            setErrorMsg("Ugyldig email");
        } else if (error.message.includes("auth/missing-email")) {
            setErrorMsg("Mangler email");
        } else {
            ////console.log("Error sending password reset email", error);
            setErrorMsg("Der skete en fejl");
        }
    }, [error]);

    const sendEmail = async () => {
        const actionCodeSettings = {
            url: 'https://insights.aris-robotics.com/login',
            handleCodeInApp: false
        };

        ////console.log("Auth", auth);
        ////console.log("Email", email);
        ////console.log("ActionCodeSettings", actionCodeSettings);
        const success = await sendPasswordResetEmail(email, actionCodeSettings)
        if (success) {
            onClose(true); // Close the dialog and indicate success
        }
    };

    return (
        <div className="ForgotPasswordDialog">
            <div className="dialog-container">
                <div className="card-container">
                    <div className="card-header">
                        <h2 className="card-header-title">Skriv din email</h2>
                    </div>
                    <div className="card-body">
                        <input
                            className="aris-input"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && sendEmail()}
                            autoFocus
                        />
                        {errorMsg && (
                            <div className="pw-reset-error">
                                {errorMsg}
                            </div>
                        )}
                        <div className="card-actions">
                            <button
                                className="aris-button-primary"
                                type="submit"
                                onClick={sendEmail}
                            >
                                Bekræft
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordDialog;
