import {useContext, createContext, useState, useEffect} from 'react';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import {db, storage} from '../firebase';
import { getDownloadURL, ref } from 'firebase/storage';

const LatestImageContext = createContext(null);

export const useLatestImage = () => {
    return useContext(LatestImageContext);
}

export const LatestImageContextProvider = ({children}) => {
    const [latestImageCards, setLatestImageCards] = useState([]);
    const [latestImageCardForUnit, setLatestImageCardForUnit] = useState({});
    const [lastRecordIdForUnit, setLastRecordIdForUnit] = useState({});

    const getLatestRecordData = async (unitId) => {
        try {
            console.log("Firebase call")

            const q = query(
                collection(db, `/units/${unitId}/inference-records`),
                orderBy("timestamp", "desc"),
                limit(1)
            );
            const snapshot = await getDocs(q);
            const records = snapshot.docs.map(doc => ({
                ...doc.data(),
                timestamp: new Date(doc.data().timestamp),
            }));
            const record = records[0];
            
            return record
        } catch (error) {
            console.error("records- Error fetching records: ", error);
        }
    }

    const getLatestImageCard = async (locationUnit) => {
        const unitId = locationUnit.unit;
        const latestImageName = locationUnit.record.img_name;
        const imageRef = ref(storage, `images/${latestImageName}`);
        var url = ""
        try {
            url = await getDownloadURL(imageRef);
        } catch {
            const defaultUrl = ""
            url = defaultUrl;

        }

        const card = {
            record: {
                ...locationUnit.record,
                timestamp: new Date(locationUnit.record.timestamp)
            },
            location: locationUnit.location,
            unit: unitId,
            url: url
        }

        return card;
    }

    const loadLatestImages = async (locationUnits) => {
        for (let i = 0; i < locationUnits.length; i++) {
            const locationUnit = locationUnits[i];
            const latestImageCard = await getLatestImageCard(locationUnit);

            setLatestImageCards((prev) => [...prev, latestImageCard]);
        }
    }

    const loadLatestImagesForRecords = async (recordDataDictionary) => {
        for (let i = 0; i < Object.values(recordDataDictionary).length; i++) {
            const recordData = Object.values(recordDataDictionary)[i];

            var exists = false;
            console.log("Exists test")
            for (let i = 0; i < latestImageCards.length; i++) {
                console.log("latestImageCards[i].unit", latestImageCards[i].unit)
                if (latestImageCards[i].unit === recordData.unit) {
                    console.log("Exists")
                    exists = true

                    if (latestImageCards[i].record.img_name !== recordData.record.img_name) {
                        console.log("Exists but different")

                        const latestImageCard = await getLatestImageCard(recordData);
                        const newLatestImageCards = latestImageCards.map((card, j) => {
                            if (j == i) {
                                return latestImageCard
                            }

                            return card
                        })

                        setLatestImageCards(newLatestImageCards)
                    }
                }
            }

            if (!exists) {
                const latestImageCard = await getLatestImageCard(recordData);
                setLatestImageCards((prev) => [...prev, latestImageCard]);
            }
        }
    }

    useEffect(() => {
        // Remove duplicates
        const seen = new Set();
        const filteredLatestImageCards = latestImageCards.filter(el => {
            const duplicate = seen.has(el.unit);
            seen.add(el.unit);
            return !duplicate;
        });
    }, [latestImageCards]);

    return (
        <LatestImageContext.Provider value={{
            latestImageCards,
            latestImageCardForUnit,

            loadLatestImages,
            loadLatestImagesForRecords
        }}>
            {children}
        </LatestImageContext.Provider>
    )
}