import React, { useEffect, useState, useCallback } from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
import { useUser } from './../../contexts/UserContext';
import { getAvailableLocations } from './../../hooks/unitDataHooks.js';
import { getUnitStateListener } from './../../hooks/unitDataHooks';

const HomePage = () => {
    const [locations, setLocations] = useState([]);
    const { userData } = useUser();
    
    const [statesForLocation, setStatesForLocation] = useState({});
    const navigate = useNavigate();

    const navigateToLocation = useCallback((locationId) => {
        ////console.log("navigateToLocation")
        navigate(`locations/${locationId}`)
    }, [navigate])

    const getColorForLocation = (locationId) => {
        if (statesForLocation[locationId]) {
            const state = statesForLocation[locationId].worstState;
            switch (state) {
                case 'Ready':
                    return 'var(--success-500)';
                case 'Error':
                    return 'var(--error-500)';
                case 'Warning':
                    return 'var(--warning-500)';
                default:
                    return 'var(--gray-500)';
            }
        }
    }

    useEffect(() => {
        var unsubscribeCallbacks = []
        if (locations) {
            for (let i = 0; i < locations.length; i++) {
                for (let j = 0; j < locations[i].units.length; j++) {
                    const unitId = locations[i].units[j];
                    const unsubscribeFirestore = getUnitStateListener(unitId, {
                        next: (log) => {
                            setStatesForLocation((prevStates) => {
                                
                                const locationId = locations[i].id;
                                let locationStatus;
                        
                                if (prevStates[locationId]) {
                                    locationStatus = { ...prevStates[locationId] };
                                    if (new Date(log.timestamp) > new Date(locationStatus.newestTimestamp)) {
                                        locationStatus.newestTimestamp = log.timestamp;
                                    }
                        
                                    if ([log.state, locationStatus.worstState].includes('Error')) {
                                        locationStatus.worstState = 'Error';
                                    } else if ([log.state, locationStatus.worstState].includes('Warning')) {
                                        locationStatus.worstState = 'Warning';
                                    } else {
                                        locationStatus.worstState = 'Ready';
                                    }
                        
                                } else {
                                    locationStatus = {
                                        newestTimestamp: log.timestamp,
                                        worstState: log.state,
                                    };
                                }

                                if (locationStatus.newestTimestamp < new Date(Date.now() - 24 * 60 * 60 * 1000)) {
                                    locationStatus.worstState = 'Disconnected';
                                }
                        
                                const newValue = { ...prevStates, [locationId]: locationStatus };
                                return newValue;
                            });
                        },
                        error: (error) => {
                            console.error("Error in unit state listener:", error);
                        }
                    });
                    unsubscribeCallbacks.push(unsubscribeFirestore)
                }
            }
        }

        return () => {
            for (let i = 0; i < unsubscribeCallbacks.length; i++) {
                unsubscribeCallbacks[i]();
            }
        }
    }, [locations]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userData && userData.companies) {
                    const locations = await getAvailableLocations(userData);
                    setLocations(locations);
                }
            } catch (error) {
                console.error("Error fetching locations: ", error);
            }
        };

        fetchData();
    }, [userData]);

    return (
        <div className="HomePage">
            <div className="wrapper">
                {locations.map((location, index) => (
                    <div key={index} className="card-wrapper" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <div 
                            className="login-container card-container" 
                            style={{ width: '100%', maxWidth: '620px', cursor: 'pointer' }}
                            onClick={() => {
                            		navigateToLocation(location.id)
                            	}
                            }
                        >
                            <div className="card-header">
                                <div className="card-header-title">
                                    {location.name}
                                </div>
                            </div>
                            <div className="card-body" style={{ marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                                <figure className="img-wrapper">
                                    <img 
                                        className="poster-image"
                                        style={location.units?.length > 0 ? {} : { filter: 'grayscale(100%)' }}
                                        src={location.poster_url}
                                        width="588"
                                        height="250"
                                        alt="Poster"
                                    />
                                </figure>
                            </div>
                            <div className="card-body location-bar" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                                <div className="location-bar-left">
                                    <span className="units-count-text">
                                        <div className="units-count-label">Enheder:</div>
                                        <div className="units-count-number">{location.units?.length}</div>
                                    </span>
                                </div>
                                <div className="location-bar-right">
                                    <div className="live-activity-wrapper">
                                        <div className="live-activity-dot" style={{ backgroundColor: getColorForLocation(location.id) }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomePage;
