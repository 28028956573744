import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSecondHeader } from './../../contexts/SecondHeaderContext';
import UnitCard from './UnitCard/UnitCard.jsx';
import './UnitsPage.css'; // Updated CSS file name to UnitsPage.css
import { getUnitActivationsTodayCount, getLatestUse } from './../../hooks/unitDataHooks';

function UnitsPage() {
    const { locationId } = useParams(); // Gets the locationId from the URL
    const { getLocationData, getUnitsData, currentLocation, resetCurrentUnit } = useSecondHeader();
    const [units, setUnits] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (locationId && units.length === 0) {
            getLocationData(locationId);
        }
        resetCurrentUnit(); // Reset the current unit when loading this page

    }, [locationId]);

    useEffect(() => {
        const subscription = async () => {
            if (currentLocation && currentLocation.units) {
                let tempUnits = await getUnitsData(currentLocation.units);
                tempUnits = tempUnits.sort((a, b) => b.display_name.localeCompare(a.display_name));

                for (var i = 0; i < tempUnits.length; i++) {
                    const unit = tempUnits[i]
                    const path = `/units/${unit.unit_id}/inference-records`;
                    ////console.log("unitNewValue", unit)
                    var unitNewValue = { ...unit};
                    unitNewValue.activations = await getUnitActivationsTodayCount(path);
                    unitNewValue.latest_use = await getLatestUse(path);
    
                    tempUnits[i] = unitNewValue;
                }

                setUnits(tempUnits.reverse());
                setLoaded(true);
            }
        };

        subscription();

        
    }, [currentLocation]);

    return (
    	<div className = "UnitsPage">
	        <div className="grid-wrapper">
	            {units.length > 0 ? (
	                units.map((unit, index) => (
	                    <UnitCard 
                            key={unit.unit_id} 
                            unit={unit}
                        />
	                ))
	            ) : (
	                loaded && <h3 style={{ textAlign: 'center' }}>Der er ingen enheder på denne lokation</h3>
	            )}
	        </div>
        </div>
    );
}

export default UnitsPage;
