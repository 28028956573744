import { collection, doc, getDoc, getDocs, query, orderBy, limit, onSnapshot, where, getCountFromServer } from 'firebase/firestore';
import { db } from './../firebase'; // Assuming db is your Firestore instance

// Function to get the last log of multiple units
export const getUnitsLastLog = async (unit_ids) => {
    const logs = [];
    for (const unit_id of unit_ids) {
        const collRef = collection(db, `units/${unit_id}/state-logs`);
        const q = query(collRef, orderBy("timestamp", "desc"), limit(1));
        console.log("Firebase call")
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            if (doc.exists()) {
                logs.push(doc.data());
            }
        });
    }
    return logs;
};

// Function to set up a listener for unit state logs
export const getUnitStateListener = (unit_id, state_sub) => {
    const collRef = collection(db, `units/${unit_id}/state-logs`);
    const q = query(collRef, orderBy("timestamp", "desc"), limit(1));
    const unsubscribe = onSnapshot(q, 
        (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    try {
                        state_sub.next(change.doc.data());
                    } catch (error) {
                        console.error("Error parsing unit state logs: ", error);
                    }
                }
            });
        },
        (error) => {
            console.error("Error getting unit state logs: ", error);
        }
    );
    return unsubscribe;
};

export const getUnitRecordsListener = (unit_id, records_subscriber) => {
    const collRef = collection(db, `units/${unit_id}/inference-records`);
    const q = query(collRef, orderBy("timestamp", "desc"), limit(1));
    const unsubscribe = onSnapshot(q,
        (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    try {
                        records_subscriber.next(change.doc.data());
                    } catch (error) {
                        console.error("Error parsing unit state logs: ", error);
                    }
                }
            });
        },
        (error) => {
            console.error("Error getting unit record: ", error);
        }
    );
    return unsubscribe;
};

// Function to get the count of unit activations today
export const getUnitActivationsTodayCount = async (path) => {
    const midnight = new Date();
    midnight.setHours(0, 0, 0, 0);

    const collInf = collection(db, path);
    const q = query(collInf, where("timestamp", ">", midnight.getTime()));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
};

// Function to get the latest use of a unit
export const getLatestUse = async (path) => {
    const collInf = collection(db, path);
    let output = '';

    const q2 = query(collInf, orderBy("timestamp", "desc"), limit(1));
    console.log("Firebase call")
    const querySnapshot = await getDocs(q2);
    querySnapshot.forEach((doc) => {
        if (doc.exists()) {
            const diff = Date.now() - doc.data()['timestamp'];

            const seconds = Math.floor(diff / 1000);
            const minutes = Math.floor(diff / 60000);
            const hours = Math.floor(diff / 3600000);
            const days = Math.floor(diff / 86400000);
            const weeks = Math.floor(diff / 604800000);
            const months = Math.floor(diff / 2629746000);
            const years = Math.floor(diff / 31556952000);

            if (seconds < 60) {
                output = `${seconds} sekunder siden`;
            } else if (minutes < 60) {
                output = `${minutes} minutter siden`;
            } else if (hours < 24) {
                output = hours === 1 ? '1 time siden' : `${hours} timer siden`;
            } else if (days < 7) {
                output = days === 1 ? '1 dag siden' : `${days} dage siden`;
            } else if (weeks < 4.348) { // Approximate weeks in a month
                output = weeks === 1 ? '1 uge siden' : `${weeks} uger siden`;
            } else if (months < 12) {
                output = months === 1 ? '1 måned siden' : `${months} måneder siden`;
            } else {
                output = years === 1 ? '1 år siden' : `${years} år siden`;
            }
        }
    });

    return output;
};

export const getAvailableLocations = async (userData) => {
    try {
        let locations = [];

        const locationCompanies = userData.location_companies;
        for (let locationCompanyKey in locationCompanies) {
            const locationCompany = locationCompanies[locationCompanyKey];
            const companyId = locationCompany["company"];
            const location_id = locationCompany["location"];
            const locationDocRef = doc(db, `companies/${companyId}/locations`, location_id);
            const locationDoc = await getDoc(locationDocRef);
            if (locationDoc.exists()) {
                locations.push(locationDoc.data());
            } else {
                console.log("No such document!");
            }
        }

        // Iterate over each company and query its locations subcollection
        for (const companyId of userData.companies) {
            const collectionRef = collection(db, `companies/${companyId}/locations`);
            console.log("Firebase call")
            const querySnapshot = await getDocs(query(collectionRef));
            const companyLocations = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            locations = locations.concat(companyLocations);

            //Put the locations with 0 units last in the list
            locations.sort((a, b) => {
                const aHasUnits = a.units?.length > 0;
                const bHasUnits = b.units?.length > 0;
                if (!aHasUnits && bHasUnits) {
                    return 1;  // b comes before a
                }
                
                return -1; // keep original order otherwise
            });
        }

        return locations;
    } catch (error) {
        console.error("Error fetching available locations: ", error);
        throw error; // Propagate the error so the caller knows something went wrong
    }
};

