import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSecondHeader } from './../../../../contexts/SecondHeaderContext';
import { getLatestUse } from './../../../../hooks/unitDataHooks';
import locationBuildingIcon from './../../../../assets/icons/location_building.svg'; // Update with the correct path
import downArrowIcon from './../../../../assets/icons/down-arrow.svg'; // Update with the correct path
import './SecondHeader.css';

const SecondHeader = ({unitId, isWadeReportMode}) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { availableLocations, currentLocation, currentUnit, updateLocations, getLocationData, latestUseString, setLatestUseString } = useSecondHeader();
    const [showLocationDropdown, setShowLocationDropdown] = useState(false);
    const [locations, setLocations] = useState([]);
    const [localCurrentLocation, setLocalCurrentLocation] = useState(null);

    useEffect(() => {
        updateLocations();
    }, []);

    useEffect(() => {
        const locationId = pathname.split('/').find((part, index, arr) => arr[index - 1] === 'locations');
        const unitId = pathname.split('/').find((part, index, arr) => arr[index - 1] === 'unit');

        if (locationId) {
            getLocationData(locationId);
        }

        if (unitId) {
        
        }

    }, [pathname]);

    useEffect(() => {
        if (currentLocation) {
            
        }
    }, [currentLocation]);
    /*
    useEffect(() => {
        ////console.log("location- locationId useEffect", locationId);
    }, [locationId]);

    useEffect(() => {
        
        ////console.log("location- useEffect - pathname")
        if (pathname.includes('unit/')) {
            const unitId = pathname.split('/').find((part, index, arr) => arr[index - 1] === 'unit');

            if (unitId === null || unitId === undefined) {
                ////console.log("location- unitId is null");
                setLocalCurrentUnit(null);
                resetCurrentUnit();
                return;
            } else if (currentUnit && unitId == currentUnit.unit_id) {
                const updateUnit = async () => {
                    if (currentUnit === null || currentUnit === undefined) {
                        setLocalCurrentUnit(null);
                        resetCurrentUnit();
                    } else {
                        const path = `/units/${currentUnit.unit_id}/inference-records`;
                        currentUnit.latest_use = await getLatestUse(path);
                        setLocalCurrentUnit(currentUnit);
                    }
                };
                
                updateUnit();
            } else {
                getUnitData(unitId);
            }

        } else {
            setLocalCurrentUnit(null);
            resetCurrentUnit();
        }
        
        if (pathname.includes('locations/')) {
            ////console.log("location- pathname includes locations");

            if (locations.length === 0) {
                ////console.log("Calling updateLocations from SecondHeader");
                updateLocations();
            }

            const locationId = pathname.split('/').find((part, index, arr) => arr[index - 1] === 'locations');
            if (locationId) {
                getLocationData(locationId);
            }
        } 
    }, [pathname]);

    useEffect(() => {
        if(currentUnit) {
            const updateUnit = async () => {
                if (currentUnit === null || currentUnit === undefined) {
                    setLocalCurrentUnit(null);
                    resetCurrentUnit();
                } else {
                    const path = `/units/${currentUnit.unit_id}/inference-records`;
                    currentUnit.latest_use = await getLatestUse(path);
                    setLocalCurrentUnit(currentUnit);
                }
            };
            
            updateUnit();
        }
    }, [currentUnit]);
*/
    useEffect(() => {
        setLocations(availableLocations);
    }, [availableLocations]);

    useEffect(() => {
        setLocalCurrentLocation(currentLocation);
    }, [currentLocation]);

     /*
    useEffect(() => {
        ////console.log("location- unit useEffect", currentUnit);

        if (!unitId) {
            ////console.log("location- unitId is null");
            setLocalCurrentUnit(null);
            resetCurrentUnit();
            return;
        }

        const updateUnit = async () => {
            if (currentUnit === null || currentUnit === undefined) {
                setLocalCurrentUnit(null);
                resetCurrentUnit();
            } else {
                const path = `/units/${currentUnit.unit_id}/inference-records`;
                currentUnit.latest_use = await getLatestUse(path);
                setLocalCurrentUnit(currentUnit);
            }
        };

        if (currentUnit === null || currentUnit == undefined) {
            setLocalCurrentUnit(null);
            resetCurrentUnit();
        } else {
            ////console.log("location- currentUnit is not null");
            updateUnit();
        }
            
    }, [currentUnit, unitId]);
*/

    /*
    useEffect(() => {
        if (locations.length === 0) {
            updateLocations();
        }
    }, [locations]);

    useEffect(() => {
        if (locationId) {
            getLocationData(locationId);
        }
    }, [locationId]);

    useEffect(() => {
        ////console.log("location- unitId useEffect", unitId);
        if (!unitId) {
            ////console.log("location- unitId is null");
            setLocalCurrentUnit(null);
            resetCurrentUnit();
        }
    }, [unitId]);

    useEffect(() => {
        setLocations(availableLocations);
    }, [availableLocations]);

    useEffect(() => {
        setLocalCurrentLocation(currentLocation);
    }, [currentLocation]);
*/
    const navigateToLocation = (location) => {
        navigate(`/locations/${location.id}`);
    };

    const locationsDropdownClick = () => {
        setShowLocationDropdown(!showLocationDropdown);
    };

    const handleOutsideClick = (event) => {
        if (!event.target.closest('.location-dropdown-toggle')) {
            setShowLocationDropdown(false);
        }
    };

    const loadLatestUseIfEmpty = async () => {
        if (currentUnit && latestUseString === '') {
            const path = `/units/${currentUnit.unit_id}/inference-records`;
            setLatestUseString(await getLatestUse(path));           
        }
    }

    useEffect(() => {
        document.addEventListener('mouseup', handleOutsideClick);
        return () => {
            document.removeEventListener('mouseup', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        loadLatestUseIfEmpty();
    }, [currentUnit, latestUseString]);

    return (
        <div className="SecondHeader">
            <div className="wrapper">
                <div className="breadcrumb">
                    <span className="path">
                        <img className="tiny-icon" src={locationBuildingIcon} alt="Location Icon" />
                        { !isWadeReportMode && (
                            <div onClick={() => navigate('/locations')} style={{ cursor: 'pointer' }}>Lokationer</div>
                        )}
                        {localCurrentLocation && (
                            <>
                                { !isWadeReportMode && (
                                    <div>/</div>
                                )}
                                <div onClick={() => navigate(`/locations/${currentLocation.id}`)} style={{ cursor: 'pointer' }} className={!currentUnit ? 'active-unit-address' : ''}>
                                    {currentLocation.name}
                                </div>
                            </>
                        )}
                        {currentUnit && (
                            <>
                                <div>/</div>
                                <div className="active-unit-address">{currentUnit.display_name}</div>
                            </>
                        )}
                    </span>
                    {currentLocation && !currentUnit && (
                        <div className="location-dropdown-wrapper">
                            <div className="location-dropdown-toggle" onClick={locationsDropdownClick}>
                                <img className={`tiny-icon ${showLocationDropdown ? 'location-dropdown-toggle-on' : ''}`} src={downArrowIcon} alt="Dropdown Icon" />
                            </div>
                            <div className={`location-dropdown ${showLocationDropdown ? '' : 'location-dropdown-hide'}`}>
                                {locations.map((location, index) => (
                                    currentLocation.id !== location.id && (
                                        <div key={index} className="location-dropdown-elem" onClick={() => navigateToLocation(location)} style={{ cursor: 'pointer' }}>
                                            {location.name}
                                        </div>
                                    )
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="right">
                    <span className="units-count-text">
                        {!isWadeReportMode && (currentUnit ? (
                            <>
                                <div className="units-count-label">Seneste anvendelse:</div>
                                <div className="units-count-number">{latestUseString || ''}</div>
                            </>
                        ) : (
                            <>
                                <div className="units-count-label">Enheder:</div>
                                <div className="units-count-number">{currentLocation?.units?.length || '0'}</div>
                            </>
                        ))}
                    </span>
                    <span className="units-count-text">
                        <div className="units-count-label">Adresse:</div>
                        <div className="units-count-number">{currentLocation?.address || ''}</div>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SecondHeader;
