import {useEffect} from "react"
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import HomePage from "./pages/HomePage/HomePage.jsx"
import DashBoardSubPage from './pages/DashBoardPage/sub-pages/DashBoardSubPage/DashBoardSubPage.jsx';
import ImagesSubPage from './pages/DashBoardPage/sub-pages/ImagesSubPage/ImagesSubPage.jsx';
import UnitsPage from "./pages/UnitsPage/UnitsPage.jsx" // Updated import to UnitsPage
import RecentPage from "./pages/Admin/RecentPage/RecentPage.jsx";
import AppHeader from "./components/NavigationFrame/AppHeader/AppHeader.jsx"
import AppFooter from "./components/NavigationFrame/AppFooter/AppFooter.jsx"
import PrivateRoute from "./components/PrivateRoute/PrivateRoute.jsx"
import AdminRoute from "./components/AdminRoute/AdminRoute.jsx"

import { auth } from './firebase';
import { signInWithCustomToken } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useUser } from './contexts/UserContext.js';
import WadeReportPage from './pages/WadeReportPage/WadeReportPage.jsx';
import WadeReportWeekPage from "./pages/WadeReportWeekPage/WadeReportWeekPage.js";
import AdminOverviewPage from "./pages/Admin/AdminOverviewPage/AdminOverviewPage.jsx";

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

function App() {
    const [user, loading] = useAuthState(auth);
    const { userData } = useUser();

    useEffect(() => {
        // Get the token from the cookie
        const customToken = getCookie('firebaseAuthToken');
    
        if (customToken) {
          // Sign in with the custom token
          ////console.log("customTokeN: ", customToken);
          signInWithCustomToken(auth, customToken)
            .then(() => {
              ////console.log("Successfully signed in with custom token");
              ////console.log("user:", user);
            })
            .catch((error) => {
              ////console.log("Error signing in with custom token: ", error);
            });
        } else {
            ////console.log('No token provided in cookie');
        }
    }, []);

    return (
        <div className="app-content-container">
            <BrowserRouter>
                {
                    user && userData
                    ? <AppHeader />
                    : <></>
                }

                <div className="router-page">
                    <Routes>
                        <Route 
                            path="/"
                            element={
                                <PrivateRoute>
                                    <HomePage />
                                </PrivateRoute>
                            }
                        />
                        <Route 
                            path="/locations"
                            element={
                                <Navigate to="/" />
                            }
                        />
                        <Route 
                            path="/login"
                            element={
                                <Navigate to="/" />
                            }
                        />
                        <Route 
                            path="/locations/:locationId"
                            element={
                                <PrivateRoute>
                                    <UnitsPage />
                                </PrivateRoute>
                            }
                        />
                        <Route 
                            path="/locations/:locationId/unit/:unitId/dashboard" 
                            element={
                                <PrivateRoute>
                                    <DashBoardSubPage />
                                </PrivateRoute>
                            }
                        />
                        <Route 
                            path="/locations/:locationId/unit/:unitId/dashboard/wade-report-mode/month" 
                            element={
                                <PrivateRoute>
                                    <WadeReportPage />
                                </PrivateRoute>
                            }
                        />
                        <Route 
                            path="/locations/:locationId/unit/:unitId/dashboard/wade-report-mode/week" 
                            element={
                                <PrivateRoute>
                                    <WadeReportWeekPage />
                                </PrivateRoute>
                            }
                        />
                        <Route 
                            path="/locations/:locationId/unit/:unitId/images" 
                            element={
                                <PrivateRoute>
                                    <ImagesSubPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="recent"
                            element={
                                <AdminRoute>
                                    <RecentPage />
                                </AdminRoute>
                            }
                        />
                        <Route
                            path="admin-overview"
                            element={
                                <AdminRoute>
                                    <AdminOverviewPage />
                                </AdminRoute>
                            }
                        />
                        
                    </Routes>
                </div>

                <AppFooter />
            </BrowserRouter>
        </div>
    );
}

export default App;
